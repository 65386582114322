import React from "react";
import Hero from "../components/Hero/Hero";
import Layout from "../components/Layout";
import Spacer from "../components/Spacer";
import { graphql } from "gatsby";
import Grid from "../components/Grid/Grid";
import GridItem from "../components/Grid/GridItem";
import TwoCol from "../components/TwoCol/TwoCol";
import Seo from "../components/Seo";

const index = ({ data: { page } }) => {
  const { bild, rubrik, underrubrik } = page.hem.hero;
  const puffar = page.hem.puffar.puff;
  const personal = page.hem.sektionPersonal;
  const about = page.hem.sektionOmOss;

  return (
    <Layout>
      <Seo
        title={"Hem"}
        description={about.text}
        image={personal.bild.localFile.childImageSharp.original.src}
      />
      <div className="container">
        <Hero title={rubrik} subtitle={underrubrik} src={bild} />
      </div>
      <Spacer />

      <div className="container">
        <Grid>
          {puffar?.length &&
            puffar.map((item, index) => (
              <GridItem
                key={index}
                title={item.rubrik}
                text={item.text}
                src={item.bild}
                slug={item.slug}
              />
            ))}
        </Grid>
      </div>
      <Spacer />
      <div className=" border-px border-t border-opacity-5 border-white"></div>
      <TwoCol
        title={personal.rubrik}
        text={personal.text}
        slug={"personal"}
        src={personal.bild}
      />
      <TwoCol
        reversed
        title={about.rubrik}
        text={about.text}
        slug={"om-oss"}
        src={about.bild}
      />
    </Layout>
  );
};

export const query = graphql`
  query HomePage {
    page: wpPage(title: { eq: "Hem" }) {
      id
      hem {
        hero {
          rubrik
          underrubrik
          bild {
            localFile {
              childImageSharp {
                original {
                  src
                }
                gatsbyImageData(
                  quality: 100
                  transformOptions: { grayscale: true }
                )
              }
            }
          }
        }
        puffar {
          puff {
            text
            slug
            rubrik
          }
        }
        sektionOmOss {
          text
          rubrik
          bild {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  transformOptions: { grayscale: true }
                )
              }
            }
          }
        }
        sektionPersonal {
          text
          rubrik
          bild {
            localFile {
              childImageSharp {
                original {
                  src
                }
                gatsbyImageData(
                  quality: 100
                  transformOptions: { grayscale: true }
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default index;
