import React from 'react'
import { Link } from 'gatsby'
import FluidImage from '../FluidImage/FluidImage'
import Spacer from '../Spacer'

const TwoCol = ({ title, text, slug, src, reversed }) => {
  return (
    <div className="grid overflow-hidden">
      <div className={`flex items-center justify-center container`}>
        <div className="flex flex-col gap-6">
          <Spacer />
          <div className="flex flex-col gap-12">
            <div className="flex flex-col gap-6 text-center">
              <h3 className="h3">{title}</h3>
              <p className="max-w-2xl">{text}</p>
            </div>
            <Link to={`/${slug}`} className="link-btn alternate self-center">
              {title}
            </Link>
          </div>
          <Spacer />
        </div>
      </div>
      <div
        className={`flex h-[30vh] lg:h-[75vh] items-center justify-center opacity-50 ${
          reversed ? 'order-1 lg:order-1' : 'order-1 lg:order-2'
        }`}
      >
        <FluidImage src={src} />
      </div>
    </div>
  )
}

export default TwoCol
