import { Link } from 'gatsby'
import React from 'react'
import './Grid.scss'

const GridItem = ({ title, text, src, slug, children }) => {
  return (
    <div className="grid-item">
      {/* {src && (
        <>
          <div className="grid-img">
            <FluidImage src={src} />
          </div>
          <div className="grid-darken"></div>
        </>
      )} */}
      <div className="inner">
        {children ? (
          children
        ) : (
          <>
            <div className="inner-header">
              <h3 className="h5">{title}</h3>
              <p className="max-w-md">{text}</p>
            </div>
            <Link to={`/${slug}`} className="link-btn alternate ">
              {title}
            </Link>
          </>
        )}
      </div>
    </div>
  )
}

export default GridItem
