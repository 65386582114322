import React from 'react'
import FluidImage from '../FluidImage/FluidImage'
import './Hero.scss'

const Hero = ({ title, subtitle, src }) => {
  return (
    <div className="hero">
      <div className="hero-img">
        <FluidImage src={src} />
      </div>
      <div className="hero-darken"></div>
      <div className="hero-inner">
        <div className="container">
          <div className="content">
            <h1>{title}</h1>
            <p className="subtitle">{subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
